import React, {useEffect, useState} from "react";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {PromoCodesURL} from "../../global/urls";
import {
    customMoment,
    generateRandomString,
    isValidValue,
    openNotification,
    showConfirm,
    showError
} from "../../global/helper_functions";
import {Button, Col, Row} from "antd";
import {ActionButton} from "../../global/components/ActionButton";
import {CustomTextField} from "../../global/components/fields/CustomTextField";
import {permission_data} from "../../global/permissions_data";
import {checkPermission} from "../../global/permissions";
import {Loading} from "../../global/components/Loading";
import CustomCheckBox from "../../global/components/fields/CustomCheckBox";
import Cookies from "js-cookie";
import CustomSelectField from "../../global/components/fields/CustomSelectField";
import {promo_code_apply_on_options, promo_code_type_options} from "../../global/variables";
import {CustomDateField} from "../../global/components/fields/CustomDateField";
import moment from "moment";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import Hide from "../../global/components/Hide";
import VendorCategoryAutoComplete from "../../global/components/fields/VendorCategoryAutoComplete";
import {VendorAutoComplete} from "../../global/components/fields/VendorAutoComplete";
import {VendorBrandAutoComplete} from "../../global/components/fields/VendorBrandAutoComplete";
import {ProductAutoComplete} from "../../global/components/fields/ProductAutoComplete";

export const PromoCodeDetails = props => {
    const is_new = props.is_new;
    const promo_code_id = props.promo_code_id || "";
    const slug = props.slug || "";

    const [has_perm] = useState(checkPermission(permission_data.promo_code.edit) || (promo_code_id === "new" && checkPermission(permission_data.promo_code.add)));
    const [data, setData] = useState({
        code: generateRandomString(8),
        type: "Discount",
        apply_on: "Products",
        discount_amount: 5,
        start_date: moment().utc(true),
        end_date: moment().utc(true).add(10, "days"),
        total_number_of_use: 100,
        number_used_per_user: 100,
        minimum_item: 1,
        minimum_spend: 1,
        maximum_spend: 30000,
        maximum_discount_amount: 30000,
        is_active: true,
    });

    const [loading, setLoading] = useState(true);
    const [helper_text, setHelperText] = useState({});
    const [show_advance_options, setShowAdvanceOptions] = useState(false);


    const is_free_delivery = data.type === "Free Delivery";

    useEffect(() => {
        if (!is_new) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [])

    const fetchData = async () => {
        let response = await httpRequestWithAuth(`${PromoCodesURL}${promo_code_id}`, "GET");
        if (response.status) {
            setData(response.data);
            props.setPromoCodeData(response.data);
        } else {
            showError("Get Promo Code Data", response.error_feedback.non_field_errors);
            onCancel();
        }
        setLoading(false);
    }


    const handleFieldChange = (name, value) => {
        let temp_data = {...data};
        temp_data[name] = value;

        setData(temp_data);
    }


    const onCancel = () => {
        props.history.push(`/${slug}/promo-codes`);
    }


    const onDelete = async () => {
        await setLoading(true);
        let response = await httpRequestWithAuth(`${PromoCodesURL}${data.id}`, "DELETE");
        if (response.status) {
            openNotification("success", "Delete Promo Code", `${data.title} promo code has been deleted`)
            onCancel();
        } else {
            openNotification("error", "Delete Promo Code", response.error_feedback.non_field_errors)
        }
        await setLoading(false);
    }


    const handleDelete = () => {
        Cookies.remove("promo_codes_open_in_page");
        showConfirm("Delete Promo Code", `Are you sure to delete ${data.title} promo code ?`, onDelete);
    }


    const onSave = async () => {
        await setLoading(true);
        setHelperText({});

        let temp_data = {
            ...data,
            start_date: isValidValue(data.start_date) ? customMoment(data.start_date).format("DD-MM-YYYY") : "",
            end_date: isValidValue(data.end_date) ? customMoment(data.end_date).format("DD-MM-YYYY") : "",
        }

        let response;

        if (is_new) {
            response = await httpRequestWithAuth(PromoCodesURL, "POST", temp_data);
        } else {
            response = await httpRequestWithAuth(`${PromoCodesURL}${data.id}`, "PUT", temp_data);
        }

        if (response.status) {
            props.setPromoCodeData(response.data);
            if (is_new) {
                openNotification("success", "Add Promo Code", `${data.title} promo code has been added`);
            } else {
                openNotification("success", "Edit Promo Code", response.data.msg || `${data.title} promo code has been updated`);
            }


            onCancel();


        } else {
            // openNotification("error", is_new?"Add PromoCode":"Edit PromoCode", response.error_feedback.non_field_errors)
            setHelperText(response.error_feedback)
        }


        setLoading(false);
    }


    if (loading) {
        return <Loading/>
    }


    return (
        <>
            <Row gutter={[12, 10]}>

                <Col xs={24} className="d-flex justify-content-between align-items-center">
                    <h2 className="font-size-22 mb-0 d-flex  align-items-center">
                        <label>
                            Details
                        </label>
                    </h2>
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Title"
                        name="title"
                        value={data.title}
                        onChange={handleFieldChange}
                        error={helper_text.title !== undefined}
                        helper_text={helper_text.title}
                    />
                </Col>


                <Col xs={24} md={12}>
                    <CustomTextField
                        view_only={!has_perm}
                        label="Code"
                        name="code"
                        value={data.code}
                        onChange={handleFieldChange}
                        error={helper_text.code !== undefined}
                        helper_text={helper_text.code}
                    />
                </Col>

                <Col xs={24} md={8}>
                    <CustomSelectField
                        view_only={!has_perm}
                        label="Type"
                        name="type"
                        value={data.type}
                        onChange={handleFieldChange}
                        error={helper_text.type !== undefined}
                        helper_text={helper_text.type}
                        options={promo_code_type_options}
                    />
                </Col>


                <Hide hide={is_free_delivery}>
                    <Col xs={24} md={8}>
                        <CustomTextField
                            view_only={!has_perm}
                            label="Discount Amount"
                            name="discount_amount"
                            value={data.discount_amount}
                            onChange={handleFieldChange}
                            error={helper_text.discount_amount !== undefined}
                            helper_text={helper_text.discount_amount}
                            type="number"
                            addonAfter="%"
                        />
                    </Col>

                    <Col xs={24} md={8}>
                        <CustomSelectField
                            view_only={!has_perm}
                            label="Apply On"
                            name="apply_on"
                            value={data.apply_on}
                            onChange={handleFieldChange}
                            error={helper_text.apply_on !== undefined}
                            helper_text={helper_text.apply_on}
                            options={promo_code_apply_on_options}
                        />
                    </Col>
                </Hide>


                <Hide hide={data.apply_on !== "Vendors Brands"}>
                    <Col xs={24}>
                        <VendorBrandAutoComplete
                            required
                            multiple
                            fetch_all
                            view_only={!has_perm}
                            label="Vendors Brands"
                            name="vendor_brands_id"
                            value={data.vendor_brands_id || []}
                            onChange={handleFieldChange}
                            error={helper_text.vendor_brands_id !== undefined}
                            helper_text={helper_text.vendor_brands_id}
                        />
                    </Col>
                </Hide>

                <Hide hide={data.apply_on !== "Vendors"}>
                    <Col xs={24}>
                        <VendorAutoComplete
                            required
                            multiple
                            fetch_all
                            view_only={!has_perm}
                            label="Vendors"
                            name="vendors_id"
                            value={data.vendors_id || []}
                            onChange={handleFieldChange}
                            error={helper_text.vendors_id !== undefined}
                            helper_text={helper_text.vendors_id}
                        />
                    </Col>
                </Hide>

                <Hide hide={data.apply_on !== "Vendor Categories"}>
                    <Col xs={24}>
                        <VendorCategoryAutoComplete
                            required
                            multiple
                            view_only={!has_perm}
                            label="Vendor Categories"
                            name="vendor_categories_id"
                            value={data.vendor_categories_id || []}
                            onChange={handleFieldChange}
                            error={helper_text.vendor_categories_id !== undefined}
                            helper_text={helper_text.vendor_categories_id}
                        />
                    </Col>
                </Hide>

                <Hide hide={data.apply_on !== "Products"}>
                    <Col xs={24}>
                        <ProductAutoComplete
                            required
                            multiple
                            fetch_in_search
                            keep_current_value
                            view_only={!has_perm}
                            label="Products"
                            name="products_id"
                            value={data.products_id || []}
                            onChange={handleFieldChange}
                            error={helper_text.products_id !== undefined}
                            helper_text={helper_text.products_id}
                        />
                    </Col>
                </Hide>

                <Col xs={24} md={is_free_delivery ? 8 : 12}>
                    <CustomDateField
                        view_only={!has_perm}
                        label="Start Date"
                        name="start_date"
                        value={data.start_date}
                        onChange={handleFieldChange}
                        error={helper_text.start_date !== undefined}
                        helper_text={helper_text.start_date}
                    />
                </Col>

                <Col xs={24} md={is_free_delivery ? 8 : 12}>
                    <CustomDateField
                        view_only={!has_perm}
                        label="End Date"
                        name="end_date"
                        value={data.end_date}
                        onChange={handleFieldChange}
                        error={helper_text.end_date !== undefined}
                        helper_text={helper_text.end_date}
                    />
                </Col>

                <Col xs={24} className="d-flex justify-content-end">
                    <Button type="link" className="font-size-14 text-dark"
                            onClick={() => setShowAdvanceOptions(!show_advance_options)}>
                        {show_advance_options ? "Hide Advance Options" : "Show Advance Options"}
                        {show_advance_options ? <UpOutlined/> : <DownOutlined/>}
                    </Button>
                </Col>


                <Hide hide={!show_advance_options}>
                    <Col xs={24} md={12}>
                        <CustomTextField
                            view_only={!has_perm}
                            label="Total Number Of Use"
                            name="total_number_of_use"
                            value={data.total_number_of_use}
                            onChange={handleFieldChange}
                            error={helper_text.total_number_of_use !== undefined}
                            helper_text={helper_text.total_number_of_use}
                            type="number"
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            view_only={!has_perm}
                            label="Number Used Per User"
                            name="number_used_per_user"
                            value={data.number_used_per_user}
                            onChange={handleFieldChange}
                            error={helper_text.number_used_per_user !== undefined}
                            helper_text={helper_text.number_used_per_user}
                            type="number"
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            view_only={!has_perm}
                            label="Minimum Item"
                            name="minimum_item"
                            value={data.minimum_item}
                            onChange={handleFieldChange}
                            error={helper_text.minimum_item !== undefined}
                            helper_text={helper_text.minimum_item}
                            type="number"
                        />
                    </Col>


                    <Col xs={24} md={12}>
                        <CustomTextField
                            view_only={!has_perm}
                            label="Maximum Discount Amount"
                            name="maximum_discount_amount"
                            value={data.maximum_discount_amount}
                            onChange={handleFieldChange}
                            error={helper_text.maximum_discount_amount !== undefined}
                            helper_text={helper_text.maximum_discount_amount}
                            type="number"
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            view_only={!has_perm}
                            label="Minimum Spend"
                            name="minimum_spend"
                            value={data.minimum_spend}
                            onChange={handleFieldChange}
                            error={helper_text.minimum_spend !== undefined}
                            helper_text={helper_text.minimum_spend}
                            type="number"
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <CustomTextField
                            view_only={!has_perm}
                            label="Maximum Spend"
                            name="maximum_spend"
                            value={data.maximum_spend}
                            onChange={handleFieldChange}
                            error={helper_text.maximum_spend !== undefined}
                            helper_text={helper_text.maximum_spend}
                            type="number"
                        />
                    </Col>

                </Hide>


                <Col xs={24} className="mt-2"/>


                <Col xs={24} md={6}>
                    <CustomCheckBox
                        view_only={!has_perm}
                        label="Is Active"
                        name="is_active"
                        value={data.is_active}
                        onChange={handleFieldChange}
                        error={helper_text.is_active !== undefined}
                        helper_text={helper_text.is_active}
                    />
                </Col>


                <Col xs={24}>
                    <ActionButton
                        error_msg={helper_text.non_field_errors}
                        onDelete={handleDelete}
                        onCancel={onCancel}
                        allow_delete={isValidValue(data.id) && checkPermission(permission_data.promo_code.delete)}
                        allow_save={has_perm}
                        onSave={onSave}
                    />
                </Col>
            </Row>
        </>

    )
}
