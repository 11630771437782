import React, {useEffect, useState} from "react";
import {getUserType, isAdmin, isVendor} from "../../global/auth_functions";
import {httpRequestWithAuth} from "../../global/axios_instance";
import {OrdersURL} from "../../global/urls";
import {isValidValue, showError} from "../../global/helper_functions";
import {Button, Col, Divider, Image, Row, Table} from "antd";
import Hide from "../../global/components/Hide";
import {ActionButton} from "../../global/components/ActionButton";
import {checkPermission} from "../../global/permissions";
import {permission_data} from "../../global/permissions_data";
import {UpdateOrderStatusModal} from "./components/UpdateOrderStatusModal";
import CustomBadge from "../../global/components/CustomBadge";
import {ViewInfo} from "../../global/components/ViewInfo";
import {UpdateOrderDeliveryByModal} from "./components/UpdateOrderDeliveryByModal";
import orderCustomerReceiptTemplate from "./templates/order_customer_receipt_template";
import orderRecipientReceiptTemplate from "./templates/order_recipient_receipt_template";


export const OrderInfo = props => {
    const slug = props.slug || "";
    const url_params = props.match.params || {};

    const [order_id] = React.useState(url_params.id || 'new');
    const [user_type] = useState(getUserType());
    const is_admin = isAdmin();
    const is_vendor = isVendor();
    const [data, setData] = useState({});
    const [open_change_status_modal, setOpenChangeStatusModal] = useState(false);
    const [open_change_delivery_b_modal, setOpenChangeDeliveryByModal] = useState(false);

    const columns = [
        {
            title: "Image",
            dataIndex: "display_image",
            width: "10%",
            render: (value) => (
                <Image src={value} width={120}/>
            )
        },
        {
            title: "Brand",
            dataIndex: "brand_name_en",
            width: "8%",
            filtered: true,
            filterSearch: true,
        },
        {
            title: "SKU",
            dataIndex: "product_sku",
            width: "5%",
            filtered: true,
            filterSearch: true,
        },
        {
            title: "Title",
            dataIndex: "product_name_en",
            width: "15%",
            filtered: true,
            filterSearch: true,
        },

        {
            title: "Qty",
            dataIndex: "qty",
            width: "5%",
            align: "center"
        },
        {
            title: "Price Overall",
            dataIndex: "price_overall",
            width: "10%",
            align: "center",
            render: (value) => value + " KWD"
        },
        {
            title: "Total Discount",
            dataIndex: "total_discount",
            width: "10%",
            align: "center",
            render: (value) => value + " KWD"
        },
        {
            title: "Total Price",
            dataIndex: "total_price",
            width: "10%",
            align: "center",
            render: (value) => value + " KWD"
        },
    ];

    const fetchData = async () => {
        let response = await httpRequestWithAuth(`${OrdersURL}${order_id}`, "GET");

        if (response.status) {
            setData(response.data);
        } else {
            showError("Get Order Data", response.error_feedback.non_field_errors);
            onCancel();
        }

    }


    useEffect(() => {
        fetchData();
    }, [])


    const onCancel = () => {
        props.history.push(`/${slug}/orders`);
    }

    const handleModelClose = (reload) => {
        if (reload) {
            fetchData()
        }
        setOpenChangeStatusModal(false);
        setOpenChangeDeliveryByModal(false);
    }


    const printReceipt = (printContents) => {
        let fakeIFrame = window.document.createElement("iframe");
        document.body.appendChild(fakeIFrame);
        let fakeContent = fakeIFrame.contentWindow;
        fakeContent.document.open();
        fakeContent.document.write(printContents);
        fakeContent.document.close();
        fakeContent.focus();
        fakeIFrame.addEventListener("load", () => {
            fakeContent.print();
            fakeContent.onafterprint = () => fakeIFrame.remove()
        });
    }


    const printCustomerReceipt = () => {
        const printContents = orderCustomerReceiptTemplate(data);
        printReceipt(printContents)
    }


    const printRecipientReceipt = () => {
        const printContents = orderRecipientReceiptTemplate(data);
        printReceipt(printContents)
    }

    return (
        <>
            <Hide hide={!open_change_status_modal}>
                <UpdateOrderStatusModal
                    open={open_change_status_modal}
                    data={data}
                    handleModelClose={handleModelClose}
                />
            </Hide>

            <Hide hide={!open_change_delivery_b_modal}>
                <UpdateOrderDeliveryByModal
                    open={open_change_delivery_b_modal}
                    data={data}
                    handleModelClose={handleModelClose}
                />
            </Hide>

            <div className="m-0 p-0 d-flex justify-content-between">
                <h5 className="mb-3 mt-2">View Order > <span className="text-black-50">{data.ref_no}</span></h5>
            </div>


            <div className="paper w-100">

                <Row gutter={[12, 10]}>

                    <Col xs={24} className="d-flex justify-content-between align-items-center">
                        <h2 className="font-size-22 mb-0 d-flex  align-items-center">
                            <label className="text-start me-3">
                                Details
                            </label>
                            <CustomBadge
                                type={data.status === "Cancelled" ? "danger" : data.status !== "Done" ? "primary" : ""}
                                bg_color="black"
                                title={data.status}
                            />

                            {/*<IsActiveView value={data.is_paid} view_only active_label="Paid" inactive_label="Unpaid"/>*/}
                        </h2>

                        <div>

                            <Hide hide={!data.is_gift_order || !checkPermission(permission_data.order.can_print_recipient)}>
                                <Button className="m-1" type="primary" onClick={printRecipientReceipt}>Print Recipient
                                    Receipt</Button>
                            </Hide>

                            <Hide hide={!checkPermission(permission_data.order.can_print_recipient)}>
                                <Button className="m-1" type="primary" onClick={printCustomerReceipt}>Print Customer
                                    Receipt</Button>
                            </Hide>

                            <Hide hide={!checkPermission(permission_data.order.delivery_by)}>
                                <Button className="m-1" type="primary"
                                        onClick={() => setOpenChangeDeliveryByModal(true)}>Change Delivery By</Button>
                            </Hide>

                            <Hide hide={!checkPermission(permission_data.order.update_status)}>
                                <Button className="m-1" type="primary" onClick={() => setOpenChangeStatusModal(true)}>Change
                                    Status</Button>
                            </Hide>
                        </div>
                    </Col>


                    <Col xs={24}>
                        <Row className="border bg-light p-3 border-radius-10">
                            <Col xs={24} md={6}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Customer Name"
                                    value={data.customer_name}
                                />
                            </Col>

                            <Col xs={24} md={6}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Customer Email"
                                    value={data.customer_email}
                                />
                            </Col>

                            <Col xs={24} md={6}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Customer Contact Number"
                                    value={data.customer_contact_number_view}
                                />
                            </Col>

                            <Col xs={24} md={6}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Scheduled Date"
                                    value={data.delivery_date_view}
                                />
                            </Col>

                            <Col xs={24}>
                                <Divider className="mt-3 mb-3"/>
                            </Col>

                            <Col xs={24} md={6}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Items Price"
                                    value={data.items_price + " KWD"}
                                />
                            </Col>


                            <Col xs={24} md={6}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Delivery Fees"
                                    value={data.delivery_fees + " KWD"}
                                />
                            </Col>


                            <Col xs={24} md={6}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Discount Amount"
                                    value={(data.total_discount_amount || "0") + " KWD"}
                                />
                            </Col>


                            <Col xs={24} md={6}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Net Total"
                                    value={data.net_total + " KWD"}
                                />
                            </Col>


                            <Col xs={24}>
                                <Divider className="mt-3 mb-3"/>
                            </Col>


                            <Col xs={24} md={is_admin ? 6 : 8}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Promo Code"
                                    value={data.promo_code}
                                />
                            </Col>


                            <Col xs={24} md={is_admin ? 6 : 8}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Promo Code Type"
                                    value={data.promo_code_type}
                                />
                            </Col>

                            <Hide hide={is_vendor}>
                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        container_class_name="p-1"
                                        title="Promo Code Discount"
                                        value={data.promo_code_discount}
                                    />
                                </Col>
                            </Hide>



                            <Col xs={24} md={6}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Is Promo Applied"
                                    value={<CustomBadge
                                        type={isValidValue(data.PromoCodeId) ? "primary" : ""}
                                        bg_color="black"
                                        title={isValidValue(data.PromoCodeId)?"Yes":"No"}
                                    />}
                                />
                            </Col>

                            <Col xs={24}>
                                <Divider className="mt-3 mb-3"/>
                            </Col>


                            <Col xs={24} md={is_admin ? 6 : 8}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Status"
                                    value={<CustomBadge
                                        type={data.status === "Cancelled" ? "danger" : data.status !== "Done" ? "primary" : ""}
                                        bg_color="black"
                                        title={data.status}
                                    />}
                                />
                            </Col>

                            <Col xs={24} md={is_admin ? 6 : 8}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Delivery By"
                                    value={data.delivery_by}
                                />
                            </Col>

                            <Hide hide={is_vendor}>
                                <Col xs={24} md={6}>
                                    <ViewInfo
                                        container_class_name="p-1"
                                        title="Vendor"
                                        value={data.vendor_name}
                                    />
                                </Col>
                            </Hide>


                            <Col xs={24} md={is_admin ? 6 : 8}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Order At"
                                    value={data.created_at_view}
                                />
                            </Col>

                        </Row>
                    </Col>


                    <Hide hide={!data.is_gift_order}>
                        <Col xs={24}>
                            <h2 className="font-size-22 mb-0 mt-5">
                                <label className="text-start">
                                    Recipient Information
                                </label>
                            </h2>
                        </Col>

                        <Col xs={24}>
                            <Row className="border bg-light p-3 border-radius-10">
                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        container_class_name="p-1"
                                        title="Recipient Name"
                                        value={data.recipient_name}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        container_class_name="p-1"
                                        title="Recipient Contact Number"
                                        value={data.recipient_contact_number}
                                    />
                                </Col>

                                <Col xs={24} md={8}>
                                    <ViewInfo
                                        container_class_name="p-1"
                                        title="Scheduled Date"
                                        value={data.delivery_date_view}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <Divider className="mt-3 mb-3"/>
                                </Col>

                                <Col xs={24}>
                                    <ViewInfo
                                        className="text-center"
                                        container_class_name="p-1"
                                        title="Message To The Recipient"
                                        value={data.message_to_the_recipient || "No Message"}
                                    />
                                </Col>

                            </Row>
                        </Col>
                    </Hide>


                    <Col xs={24}>
                        <h2 className="font-size-22 mb-0 mt-5">
                            <label className="text-start">
                                Address
                            </label>
                        </h2>
                    </Col>

                    <Col xs={24}>
                        <Row className="border bg-light p-3 border-radius-10">
                            <Col xs={12}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="City"
                                    value={data.city}
                                />
                            </Col>


                            <Col xs={12}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Zip Code"
                                    value={data.zip_code}
                                />
                            </Col>

                            <Col xs={24}>
                                <Divider className="mt-3 mb-3"/>
                            </Col>

                            <Col xs={24}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Address Line One"
                                    value={data.address_line_one}
                                />
                            </Col>

                            <Col xs={24}>
                                <Divider className="mt-3 mb-3"/>
                            </Col>

                            <Col xs={24}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Address Line Two"
                                    value={data.address_line_two}
                                />
                            </Col>

                            <Col xs={24}>
                                <Divider className="mt-3 mb-3"/>
                            </Col>

                            <Col xs={24}>
                                <ViewInfo
                                    container_class_name="p-1"
                                    title="Address Line Three"
                                    value={data.address_line_three}
                                />
                            </Col>

                        </Row>
                    </Col>


                    <Col xs={24}>
                        <h2 className="font-size-22 mb-0 mt-5">
                            <label className="text-start">
                                Order Item
                            </label>
                        </h2>
                    </Col>


                    <Col xs={24}>
                        <Table
                            columns={columns}
                            dataSource={data.OrderItems || []}
                            pagination={false}
                            scroll={{
                                x: 750,
                            }}
                        />
                    </Col>


                    <Col xs={24}>
                        <ActionButton
                            onCancel={onCancel}
                        />
                    </Col>
                </Row>
            </div>

        </>)
}
